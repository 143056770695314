import React, { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
//import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
//import { backgroundColor1 } from "../../constants/constant";
import { CustomParseFloat, formatUTCDate, formatUTCDateTime, getStrangleMarketAction, getOrderStatusStyle, getNewtrendOrderStatusStyle, getMarketActionName, getMarketActionTriggerName, convertTimeZone, getUtcDateTime, getSettingsDateTimeFormat } from "../../services/common.service";
import firebase from 'firebase/compat/app'
import "firebase/compat/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import api from '../../helpers/api_helper';
import { DateTime } from "luxon";
import {
      Card,
      CardBody,
      Col,
      Container,
      Row,
      Badge, Label
} from "reactstrap";
var count = 0;
const ListOptions = (props) => {
      document.title = "Transactions";
      firebase.auth();
      //const [info , setInfo] = useState([]);
      const [form1_loader, setForm1Loader] = useState({ loading: false });
      //Filter options
      const [filters, setFilters] = useState({ strategy: ['all'], created_by: ['all'], status: ['closed'], expiry_date: '' });
      const [selectDate, setSelectDate] = useState();
      const [filterStartDate, setFilterStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString());
      const [filterEndDate, setFilterEndDate] = useState(new Date(new Date()).toISOString());
      const [filteredOrders, setFilteredOrders] = useState([]);
      const [summary, setSummary] = useState({});
      const currentUser = JSON.parse(localStorage.getItem('user'));
      let uid = currentUser.uid;
      const [TotalPNL, setTotalPNL] = useState({})
      const [OrdersCount, setOrdersCount] = useState([])
      const [selectedMulti, setselectedMulti] = useState(null);
      const [selectedMulti2, setselectedMulti2] = useState(null);
      const [selectedMulti3, setselectedMulti3] = useState(null);
      const [isDisabled, setisDisabled] = useState(false)
      const [todaydata, settodaydata] = useState(false)
      const [exchangeType, setExchangeType] = useState(1)
      useEffect(() => {
            count = 0;
            setisDisabled(true)
            setFilteredOrders([]); //Set empty before filter
            // fetchFilteredOrders(uid); 
            fetchFilteredOrdersbackend(uid)//Fetch orders
            fetchSummary(uid);
      }, [filters, filterStartDate, filterEndDate])

      // useEffect(()=>{
      //   count = count + 1
      //   if (count === 1){
      //     get_latest_fetchSummary(uid)
      //   }
      // })
      // useEffect(()=>{
      //   var createdatchange1 = false
      //   var summary_created_at = localStorage.getItem('summary_created_at')
      //   var time_difference = new Date().getTime() - new Date(summary_created_at).getTime() 
      //   var days_difference = time_difference / (1000 * 60 * 60 * 24);
      //   if(days_difference.toFixed(0) > 0){
      //     localStorage.removeItem('summary')
      //   }
      //   if (localStorage.getItem('summary') === null){
      //     createdatchange1 = true
      //     fetchSummary(uid);
      //   }else{
      //     createdatchange1 = false
      //     var data = JSON.parse(localStorage.getItem('summary'));
      //     setSummary({
      //       ...summary,
      //       total_orders: data[0].total_orders,
      //       admin_orders: data[0].admin_orders,
      //       user_orders: data[0].user_orders,
      //       total_pnl: Number(data[0].total_pnl)
      //     });  
      //   }
      //   if (createdatchange1 === true){
      //     localStorage.removeItem('summary_created_at')
      //     localStorage.setItem('summary_created_at',new Date())
      //   }
      // },[todaydata]) 
      const { SearchBar, ClearSearchButton } = Search;
      const items = [];
      //Filters
      const handleFilterChange = (e) => {
            setFilters({ ...filters, [e.target.name]: e.target.value });
      }
      function convert(str) {
            var date = new Date(str),
                  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
                  day = ("0" + date.getDate()).slice(-2);
            return [date.getFullYear(), mnth, day].join("-");
      }
      const handleExpiryDateChange = (date) => {
            setSelectDate(date)
            if (date) {
                  let converted_date = new Date(date);
                  //Add 17 hours and 30 minutes to match with delta's default expiry time
                  converted_date.setHours(converted_date.getHours() + 17);
                  converted_date.setMinutes(converted_date.getMinutes() + 30);
                  setFilters({ ...filters, expiry_date: converted_date.toISOString() });
            } else {
                  setFilters({ ...filters, expiry_date: "" });
            }

      }
      const handleCreatedDateChange = (selectedDates, datestr) => {
            if (selectedDates && selectedDates.length) {
                  let converted_date = new Date(convert(selectedDates[0]));
                  converted_date.setHours(0, 0, 0, 0);
                  setFilterStartDate(converted_date.toISOString());
                  if (selectedDates[1]) {
                        let converted_end_date = new Date(convert(selectedDates[1]));
                        converted_end_date.setHours(23, 59, 59, 999);
                        setFilterEndDate(converted_end_date.toISOString());
                  }
            } else {
                  setFilterStartDate(defaultStartDate);
                  setFilterEndDate(defaultEndDate);
            }
      }
      // const get_latest_fetchSummary= (uid) =>{
      //   var summary_created_at = localStorage.getItem('summary_created_at')
      //   let converted_date = new Date();
      //   let converted_start_date = new Date(summary_created_at);
      //   let converted_end_date2 = new Date(converted_date);
      //   var firestoreDb1=firebase.firestore().collection("users").doc(uid);
      //   firestoreDb1.collection("history").where('created_at', '>=', converted_start_date.toISOString()).where('created_at', '<=', converted_end_date2.toISOString()).get().then((allOrders) => {
      //     if (allOrders.docs.length !== 0){
      //     let orders_info = [];
      //     allOrders.forEach(function(doc) {
      //       var data = doc.data();
      //       orders_info.push(data);
      //     });
      //     if (orders_info.length > 0 || orders_info.filter(obj => obj.admin_bot).length > 0 || orders_info.filter(obj => !obj.admin_bot).length > 0 || orders_info.filter(order => order.status === 'closed').length > 0 ) {
      //       settodaydata(true)
      //     }
      //   }else{
      //     settodaydata(false)
      //   }
      //   })
      // }
      const fetchSummary = async (uid) => {

            // var firestoreDb1=firebase.firestore().collection("users").doc(uid);
            var body = {
                  uid: uid
            }
            await api({ contentType: true, auth: true }).post('/transaction/transaction-history-data', body).then(async (res) => {
                  var response = res.data.response.data;
                  setExchangeType(res.data.response.type)
                  let orders_info = [];
                  response.forEach(function (doc) {
                        var data = doc.data;
                        data.id = doc.id;
                        data.expiry_date = formatUTCDate(data.expiry_date);
                        data.created_at = getSettingsDateTimeFormat(data.created_at);  // change function
                        data.triggered_at = data.triggered_at ? getSettingsDateTimeFormat(data.triggered_at) : data.updated_at;
                        data.region = doc.type
                        orders_info.push(data);
                  });

                  //Total orders
                  let total_orders = orders_info.length;

                  //Admin orders
                  let admin_orders = orders_info.filter(obj => obj.admin_bot).length

                  //User orders
                  let user_orders = orders_info.filter(obj => !obj.admin_bot).length

                  //Total PNL
                  let total_pnl = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {
                        let pnl;
                        if (item.strategy === 'strangles' || item.strategy === 'trend_following') {
                              pnl = (item.booked_pnl ? Number(item.booked_pnl) * 0.001 * item.filled_quantity : item.realized_pnl ? Number(item.realized_pnl) : 0);
                        } else {
                              pnl = (item.booked_pnl ? Number(item.booked_pnl) : item.realized_pnl ? Number(item.realized_pnl) : 0);
                        }

                        return Number(sum) + Number(pnl);
                  }, 0);

                  setSummary({
                        ...summary,
                        total_orders: total_orders,
                        admin_orders: admin_orders,
                        user_orders: user_orders,
                        total_pnl: Number(total_pnl)
                  });
                  // localStorage.setItem('summary',('[{"total_orders":"'+(total_orders)+'","admin_orders":"'+(admin_orders)+'","user_orders":"'+(user_orders)+'","total_pnl":"'+(Number(total_pnl))+'"}]').toString())       
            }).catch(err => {

            })
      }

      //Function to get user tranasctions
      const fetchFilteredOrdersbackend = async (uid) => {
            try {
                  setForm1Loader({
                        loading: true,
                  });
                  var body = {
                        uid: uid
                  }
                  let order_info = [];
                  let orders_info = [];
                  let ListedPNL = [];
                  await api({ contentType: true, auth: true }).post('/transaction/transaction-history-data', body).then(async (res) => {
                        var response = res.data.response.data;
                        let lists = ['strangles', 'diagonals', 'straddles', 'trend_following']
                        let lists1 = ['Strangles', 'Diagonals', 'Straddles', 'Trend Following']
                        let color_list = ['border-2 border-warning', 'border-2 border-success', 'border-2 border-dark', 'border-2 border-primary']
                        await response.forEach(function (doc) {
                              var data = doc.data;
                              let strategyfilter = false
                              let created_byfilter = false
                              let statusfilter = false
                              // Stategy Filter
                              filters.strategy.map((filter) => {
                                    if (filter === data.strategy) {
                                          strategyfilter = true
                                    }
                                    if (filter === 'all') {
                                          strategyfilter = true
                                    }
                              })
                              // created by filter
                              filters.created_by.map((filter) => {
                                    if (filter === 'user') {
                                          if (!data.admin_bot) {
                                                created_byfilter = true
                                          }
                                    }
                                    if (filter === 'admin') {
                                          if (data.admin_bot) {
                                                created_byfilter = true
                                          }
                                    }
                                    if (filter === 'all') {
                                          created_byfilter = true
                                    }
                              })
                              // status Filter
                              filters.status.map((filter) => {
                                    if (filter === data.status) {
                                          statusfilter = true
                                    }
                                    if (filter === 'all') {
                                          statusfilter = true
                                    }
                              })
                              if (strategyfilter === true && created_byfilter === true && statusfilter == true) {
                                    checkFilters(data).then(response => {
                                          if (!response) {
                                                return false;
                                          }
                                          data.id = doc.id;
                                          data.expiry_date = formatUTCDate(data.expiry_date);
                                          data.created_at = getSettingsDateTimeFormat(data.created_at); // change function
                                          orders_info.push(data)
                                          order_info.push(data)
                                          setFilteredOrders(order_info);

                                    }).catch(err => {
                                          setForm1Loader({
                                                loading: false,
                                          });
                                    });
                              }
                        });
                        // All Startegy orders and pnl data loop 
                        lists.map((lis, index) => {
                              if (orders_info.filter(order => order.strategy === lis).length !== 0) {
                                    let orders = orders_info.filter(order => order.strategy === lis).length;
                                    let total_pnl = orders_info.filter(order => order.status === 'closed' && order.strategy === lis).reduce((sum, item) => {
                                          let pnl;
                                          if (item.strategy === 'strangles' || item.strategy === 'trend_following') {
                                                pnl = (item.booked_pnl ? Number(item.booked_pnl) * 0.001 * item.filled_quantity : item.realized_pnl ? Number(item.realized_pnl) : 0);
                                          } else {
                                                pnl = (item.booked_pnl ? Number(item.booked_pnl) : item.realized_pnl ? Number(item.realized_pnl) : 0);
                                          }
                                          return Number(sum) + Number(pnl);
                                    }, 0);
                                    let list = lists1[index];
                                    let totalpnl = Number(total_pnl)
                                    let color = color_list[index]
                                    let data = { list, orders, totalpnl, color }
                                    ListedPNL.push(data)
                              }
                        })
                        setOrdersCount(ListedPNL)
                        // Total PNL
                        let total_pnl = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {
                              let pnl;
                              if (item.strategy === 'strangles' || item.strategy === 'trend_following') {
                                    pnl = (item.booked_pnl ? Number(item.booked_pnl) * 0.001 * item.filled_quantity : item.realized_pnl ? Number(item.realized_pnl) : 0);
                              } else {
                                    pnl = (item.booked_pnl ? Number(item.booked_pnl) : item.realized_pnl ? Number(item.realized_pnl) : 0);
                              }
                              return Number(sum) + Number(pnl);
                        }, 0);
                        let total_fee = orders_info.filter(order => order.status === 'closed').reduce((sum, item) => {
                              let fee = 0;
                              fee = item.market_action_order !== undefined ? item.market_action_order.paid_commission ? item.market_action_order.paid_commission : 0:0
                              fee = Number(fee) + Number(item.paid_commission !== undefined ? item.paid_commission : 0)
                              return Number(sum) + Number(fee);
                        }, 0);
                        setTotalPNL({
                              totalfee: Number(total_fee),
                              totalpnl: Number(total_pnl)
                        })
                        setisDisabled(false)
                        setForm1Loader({
                              loading: false,
                        });
                  }).catch(err => {
                        setForm1Loader({
                              loading: false,
                        });
                  })
            } catch (err) {

            }
      }


      //Function to filter user transactions
      const checkFilters = async (order_data) => {
            let is_filter_valid = true;
            if (filters) {

                  //Expiry date filter
                  if (is_filter_valid && filters.expiry_date) {
                        if (new Date(filters.expiry_date).getTime() === new Date(order_data.expiry_date).getTime()) {
                              is_filter_valid = true;
                        } else {
                              is_filter_valid = false;
                        }
                  }

                  //Created Date range filter
                  if (is_filter_valid && filterStartDate && filterEndDate) {
                        let created_at = order_data.created_at;
                        if (new Date(filterStartDate).getTime() === new Date(filterEndDate).getTime()) {
                              if (formatUTCDate(created_at) === formatUTCDate(filterStartDate)) {
                                    is_filter_valid = true;
                              } else {
                                    is_filter_valid = false;
                              }
                        } else {
                              if (new Date(created_at).getTime() >= new Date(filterStartDate).getTime() && new Date(created_at).getTime() <= new Date(filterEndDate).getTime()) {
                                    is_filter_valid = true;
                              } else {
                                    is_filter_valid = false;
                              }
                        }
                  }

                  return is_filter_valid ? true : false;
            } else {
                  return true;
            }
      }

      // const convertTz = (datetime, fromTz, toTz, format='dd/MM/yyyy HH:mm:ss') => {
      //   let kkk =  DateTime.fromFormat(datetime, format, { zone: fromTz }).setZone(toTz).toFormat(format);
      //   console.log('kkkkkk...',kkk);
      // }
      //let exchange_currencies = [];
      filteredOrders.map((data) => {
            items.push(data);
            return items;
      })
      //exchange_currencies = items.filter((item, index) => items.indexOf(item) === index);
      //console.log('items....',exchange_currencies);

      const columns = [
            {
                  dataField: 'strategy', text: 'Strategy', sort: true, headerStyle: { width: "10%" }, formatter: (value, row) => {
                        return <>
                              {
                                    <div>
                                          <span className="text-capitalize">
                                                {row.strategy == 'trend_following' ? 'Trend Following' : row.strategy}
                                          </span>
                                          {(row.settings && row.settings.automatic_bot && row.settings.automatic_bot == 1 || (row.automatic_bot)) ? <div><Badge pill color="warning" className="me-1">Automated Admin Bot</Badge></div> : row.admin_bot ? <div><Badge pill color="primary" className="me-1">Admin Bot</Badge></div> : ''}
                                    </div>
                              }
                        </>
                  }
            },
            { dataField: 'expiry_date', text: 'Expiry Date', sort: true, headerStyle: { width: "10%" } },
            { dataField: 'direction', text: 'Side', formatter: (cell) => { return <>{<span className={cell === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{cell.toUpperCase()}</span>}</> }, sort: true, headerStyle: { width: "5%" } },
            {
                  dataField: 'symbol', text: 'Symbol', sort: true, headerStyle: { width: "28%" }, formatter: (value, row) => {
                        return <>
                              {row.strategy === 'strangles' || row.strategy === 'trend_following' ?
                                    <div>
                                          {
                                                <span className={row.direction === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{value}</span>}<div>
                                                <span className="text-muted font-size-10 ">{getStrangleMarketAction(row)}</span></div>
                                    </div> :
                                    <div>
                                          {
                                                <span className={row.direction === "buy" ? "badge bg-success uppercase" : "badge bg-danger uppercase"}>{value}</span>
                                          }
                                          {row.market_action && row.market_action_order ?
                                                <div>
                                                      <span className="text-muted font-size-10 ">{getMarketActionName(row)} {row.market_action_trigger ?
                                                            <span className="text-muted font-size-10 ">({getMarketActionTriggerName(row)})</span>
                                                            : ''
                                                      } @ {getSettingsDateTimeFormat(row.market_action_at)}</span>

                                                </div>
                                                :
                                                ''
                                          }
                                    </div>
                              }
                        </>
                  }
            },
            { dataField: 'price', text: 'Price', sort: true, headerStyle: { width: "5%" }, formatter: (value, row) => { return <>{<span className={row.direction === "buy" ? "text-success" : "text-danger"}>{(row.average_fill_price && row.direction !== "buy" && row.status == 'closed') ? Number(row.average_fill_price).toFixed(2) : row.price ? Number(row.price).toFixed(2) : "..."}</span>}</> } },

            {
                  dataField: 'order_id', text: 'Order ID', sort: true, headerStyle: { width: "8%" }, formatter: (value, row) => {
                        return <>{
                              <span>{value ? value : "..."}</span>}</>
                  }
            },
            {
                  dataField: 'quantity', text: 'Qty', sort: true, headerStyle: { width: "5%" }, formatter: (value, row) => {
                        return <>
                              {
                                    row.filled_quantity
                              }
                        </>
                  }
            },
            { dataField: 'status', text: 'Status', sort: true, headerStyle: { width: "6%" }, formatter: (value, row) => { return <> {row.strategy == "trend_following" ? <span dangerouslySetInnerHTML={{ __html: getNewtrendOrderStatusStyle(row, "history") }}></span> : <span dangerouslySetInnerHTML={{ __html: getOrderStatusStyle(row, "history") }}></span>} </> } },
            {
                  dataField: 'booked_pnl', text: 'PNL', sort: true, headerStyle: { width: "7%" }, formatter: (value, row) => {
                        let pnl;

                        if (row.strategy === 'strangles' || row.strategy === 'trend_following') {
                              pnl = row.booked_pnl ? row.booked_pnl * 0.001 * row.filled_quantity : row.realized_pnl ? row.realized_pnl : '';
                        } else {
                              pnl = row.booked_pnl ? row.booked_pnl : row.realized_pnl ? row.realized_pnl : '';
                        }

                        return <>
                              {
                                    // <span title={pnl ? pnl : ''} className={pnl > 0 ?"text-success" : "text-danger"}>
                                    //       {pnl ? CustomParseFloat(pnl, 4) + exchangeType === 1 ? "USD" : "USDT" : '...'}
                                    // </span>
                                    <span
                                    title={pnl || ''}
                                    className={pnl > 0 ? "text-success" : "text-danger"}
                                    >
                                    {pnl
                                    ? `${CustomParseFloat(pnl, 4)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                    : "..."}
                                    </span>

                              }
                        </>
                  }
            },
            // { dataField: 'created_at', text: 'Created Date', sort: true, headerStyle : { width: "13%" },formatter: (value, row) => { return <>{

            //   <span>{convertTimeZone(value,'Etc/UTC',currentUser.profile.time_zone)}</span>}</>} },
            { dataField: 'created_at', text: 'Created Date', sort: true, headerStyle: { width: "13%" } },
      ];

      const emptyDataMessage = () => { return form1_loader.loading ? 'Loading...' : 'No Data Found' }
      const defaultSorted = [
            {
                  dataField: "created_at", // if dataField is not match to any column you defined, it will be ignored.
                  order: "desc", // desc or asc
            },
      ];
      const options = {
            page: 1,
            sizePerPage: 100,
            lastPageText: '>>',
            firstPageText: '<<',
            nextPageText: '>',
            prePageText: '<',
            showTotal: true,
            alwaysShowAllBtns: true,
            hideSizePerPage: true,
            custom: true,
            totalSize: items.length
      };
      const StrategyList = [
            { value: 'all', label: 'All' },
            { value: 'strangles', label: 'Strangles' },
            { value: 'diagonals', label: 'Diagonals' },
            { value: 'straddles', label: 'Straddles' },
            { value: 'trend_following', label: 'Trend Following' }
      ]
      const CreatedbyList = [
            { value: 'all', label: 'All' },
            { value: 'user', label: 'Me' },
            { value: 'admin', label: 'Admin' }
      ]
      const StatusList = [
            { value: 'all', label: 'All' },
            { value: 'closed', label: 'Closed' },
            { value: 'cancelled', label: 'Cancelled' }
      ]


      function handleMultiStrategy(selectedMulti) {
            setselectedMulti(selectedMulti);
            let list = [];
            selectedMulti.map((item) => {
                  list.push(item.value)
            })
            if (list.length === 0) {
                  list.push('all')
            }
            setFilters({ ...filters, ['strategy']: list });
      }
      function handleMulticreatedby(selectedMulti2) {
            setselectedMulti2(selectedMulti2);
            let list = [];
            selectedMulti2.map((item) => {
                  list.push(item.value)
            })
            if (list.length === 0) {
                  list.push('all')
            }
            setFilters({ ...filters, ['created_by']: list });
      }
      function handleMultistatus(selectedMulti3) {
            setselectedMulti3(selectedMulti3);
            let list = [];
            selectedMulti3.map((item) => {
                  list.push(item.value)
            })
            if (list.length === 0) {
                  list.push('all')
            }
            setFilters({ ...filters, ['status']: list });
      }

      return (
            <React.Fragment>
                  <div className="page-content transaction">
                        <Container fluid>
                              <Breadcrumbs title="DeriSnap" breadcrumbItem="Transactions" />
                              <Row>
                                    <Col lg="12">
                                          <Row>
                                                <Col md="3">
                                                      <Card className="mini-stats-wid box_4">
                                                            <CardBody>
                                                                  <div className="d-flex">
                                                                        <div className="flex-grow-1">
                                                                              <p className="text-muted fw-medium">
                                                                                    Total Orders
                                                                              </p>
                                                                              <h4 className="mb-0">{summary.total_orders ? summary.total_orders : 'NA'}</h4>
                                                                        </div>
                                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                              <span className="avatar-title">
                                                                                    <i
                                                                                          className={
                                                                                                "far fa-file-alt font-size-24"
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                                <Col md="3">
                                                      <Card className="mini-stats-wid box_4">
                                                            <CardBody>
                                                                  <div className="d-flex">
                                                                        <div className="flex-grow-1">
                                                                              <p className="text-muted fw-medium">My Orders</p>
                                                                              <h4 className="mb-0">{summary.user_orders ? summary.user_orders : 'NA'}</h4>
                                                                        </div>
                                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                              <span className="avatar-title">
                                                                                    <i
                                                                                          className={
                                                                                                "far fa-file-alt font-size-24"
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                                <Col md="3">
                                                      <Card className="mini-stats-wid box_4">
                                                            <CardBody>
                                                                  <div className="d-flex">
                                                                        <div className="flex-grow-1">
                                                                              <p className="text-muted fw-medium">
                                                                                    Admin Orders
                                                                              </p>
                                                                              <h4 className="mb-0">{summary.admin_orders ? summary.admin_orders : 'NA'}</h4>
                                                                        </div>
                                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                              <span className="avatar-title">
                                                                                    <i
                                                                                          className={
                                                                                                "far fa-file-alt font-size-24"
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                                <Col md="3">
                                                      <Card className="mini-stats-wid box_4">
                                                            <CardBody>
                                                                  <div className="d-flex">
                                                                        <div className="flex-grow-1">
                                                                              <p className="text-muted fw-medium">Total PNL</p>
                                                                              {/* {summary.total_pnl > 0 ?
                                                                                    <h4 className="text-success mb-0">
                                                                                          {summary.total_pnl ? summary.total_pnl.toFixed(2) + exchangeType ==1?"USD":"USDT" : 'NA'}
                                                                                    </h4> : <h4 className="text-danger  mb-0">
                                                                                          {summary.total_pnl ? summary.total_pnl.toFixed(2) + exchangeType ==1?"USD":"USDT" : 'NA'}
                                                                                    </h4>
                                                                              } */}
                                                                              {summary.total_pnl > 0 ? (
                                                                              <h4 className="text-success mb-0">
                                                                                    {summary.total_pnl !== undefined
                                                                                    ? `${summary.total_pnl.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                    : "NA"}
                                                                              </h4>
                                                                              ) : (
                                                                              <h4 className="text-danger mb-0">
                                                                                    {summary.total_pnl !== undefined
                                                                                    ? `${summary.total_pnl.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                    : "NA"}
                                                                              </h4>
                                                                              )}

                                                                        </div>
                                                                        <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                              <span className="avatar-title">
                                                                                    <i
                                                                                          className={
                                                                                                "fas fa-hand-holding-usd  font-size-24"
                                                                                          }
                                                                                    />
                                                                              </span>
                                                                        </div>
                                                                  </div>
                                                            </CardBody>
                                                      </Card>
                                                </Col>
                                          </Row>
                                          <Card className="box_4">
                                                <CardBody className="border-bottom">
                                                      <Row>
                                                            <Col xxl={1} lg={6} className="d-flex align-items-center">
                                                                  <h5><b>Filter</b></h5>
                                                            </Col>
                                                            <Col xxl={2} lg={6}>
                                                                  <Label htmlFor="strategy">Strategy</Label>
                                                                  <Select
                                                                        name="strategy"
                                                                        label="Strategy"
                                                                        value={selectedMulti}
                                                                        isMulti={true}
                                                                        onChange={(e) => {
                                                                              setisDisabled(true)
                                                                              handleMultiStrategy(e);
                                                                        }}
                                                                        options={StrategyList}
                                                                        classNamePrefix="select2-selection"
                                                                        isDisabled={isDisabled}
                                                                  />
                                                            </Col>
                                                            <Col xxl={2} lg={6}>
                                                                  <Label htmlFor="created_by">Created by</Label>
                                                                  <Select
                                                                        name="created_by"
                                                                        label="Created by"
                                                                        value={selectedMulti2}
                                                                        isMulti={true}
                                                                        onChange={(e) => {
                                                                              setisDisabled(true)
                                                                              handleMulticreatedby(e);
                                                                        }}
                                                                        options={CreatedbyList}
                                                                        classNamePrefix="select2-selection"
                                                                        isDisabled={isDisabled}
                                                                  />
                                                            </Col>
                                                            <Col xxl={2} lg={6}>
                                                                  <Label htmlFor="status">Status</Label>
                                                                  <Select
                                                                        name="status"
                                                                        label="Status"
                                                                        value={selectedMulti3}
                                                                        isMulti={true}
                                                                        onChange={(e) => {
                                                                              setisDisabled(true)
                                                                              handleMultistatus(e);
                                                                        }}
                                                                        options={StatusList}
                                                                        classNamePrefix="select2-selection"
                                                                        isDisabled={isDisabled}
                                                                  />
                                                            </Col>
                                                            <Col xxl={2} lg={6}>
                                                                  <Label htmlFor="status">Expiry date</Label>
                                                                  <DatePicker
                                                                        className="form-control mb-3 mb-xxl-0" placeholderText="Select Date" selected={selectDate} dateFormat="dd/MM/yyyy"
                                                                        onChange={handleExpiryDateChange}
                                                                        disabled={isDisabled}
                                                                        isClearable
                                                                        onKeyDown={(e) => {
                                                                              e.preventDefault();
                                                                        }}
                                                                  />
                                                            </Col>
                                                            <Col xxl={2} lg={6}>
                                                                  <Label htmlFor="Created">Created date</Label>
                                                                  <Flatpickr
                                                                        className="form-control d-block"
                                                                        placeholder="Created Date"
                                                                        options={{
                                                                              mode: "range",
                                                                              dateFormat: "d/m/Y",
                                                                              defaultDate: [new Date(new Date().setDate(new Date().getDate() - 1)), new Date()]
                                                                        }}
                                                                        onChange={handleCreatedDateChange}
                                                                        disabled={isDisabled}
                                                                  />
                                                            </Col>

                                                      </Row>
                                                </CardBody>
                                                <Row className="p-1 px-3" >
                                                      {OrdersCount.length !== 0 ?
                                                            (OrdersCount.map((orders, index) => (
                                                                  <Col lg="2" key={index}>
                                                                        <div className={(orders.color ? orders.color : '') + " border p-2 rounded mt-1"}>
                                                                              <div className="d-flex align-items-center mb-0">
                                                                                    <h6 className="font-weight-bold mb-0">{orders.list}</h6>
                                                                              </div>
                                                                              <Row className="px-2 justify-content-between">
                                                                                    <div className="col-lg-4 align-self-start px-1">
                                                                                          <div className="mt-2">
                                                                                                <p className="form-label m-0 mb-1">Orders</p>
                                                                                                <h6 className="font-size-11 font-weight-bold">{orders.orders}</h6>
                                                                                          </div>
                                                                                    </div>

                                                                                    <div className="col-lg-4 p-0">
                                                                                          <div className="float-end mt-2">
                                                                                                <p className="form-label  m-1" >Total PNL</p>
                                                                                                {/* {orders.totalpnl > 0 ?
                                                                                                      <h6 className="text-success mb-0 font-size-10 px-1">
                                                                                                            {orders.totalpnl ? orders.totalpnl.toFixed(2) + exchangeType === 1 ? "USD" : "USDT" : 'NA'}
                                                                                                      </h6> : <h6 className="text-danger  mb-0 font-size-10">
                                                                                                            {orders.totalpnl ? orders.totalpnl.toFixed(2) + exchangeType === 1 ? "USD" : "USDT": 'NA'}
                                                                                                      </h6>
                                                                                                } */}
                                                                                                {orders.totalpnl > 0 ? (
                                                                                                      <h6 className="text-success mb-0 font-size-10 px-1">
                                                                                                            {orders.totalpnl !== undefined
                                                                                                            ? `${orders.totalpnl.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                                            : "NA"}
                                                                                                      </h6>
                                                                                                      ) : (
                                                                                                      <h6 className="text-danger mb-0 font-size-10 px-1">
                                                                                                            {orders.totalpnl !== undefined
                                                                                                            ? `${orders.totalpnl.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                                            : "NA"}
                                                                                                      </h6>
                                                                                                      )}
                                                                                          </div>
                                                                                    </div>
                                                                              </Row>
                                                                        </div>
                                                                  </Col>
                                                            ))
                                                            ) : ""
                                                      }

                                                </Row>
                                                <CardBody>
                                                      <PaginationProvider
                                                            pagination={paginationFactory(options || [])}
                                                            //keyField="order_id"
                                                            columns={columns || []}
                                                            data={items || []}
                                                      >
                                                            {({ paginationProps, paginationTableProps }) => (
                                                                  <ToolkitProvider
                                                                        //keyField="order_id"
                                                                        data={items}
                                                                        columns={columns || []}
                                                                        search
                                                                  >
                                                                        {toolkitProps => (
                                                                              <React.Fragment>
                                                                                    <CardBody className="border-bottom px-0 pt-0">
                                                                                          <Row className="g-2">
                                                                                                {/* <Row className="mb-2"> */}
                                                                                                <Col>
                                                                                                      <div className="pagination pagination-rounded justify-content-end">
                                                                                                            <PaginationListStandalone
                                                                                                                  {...paginationProps}
                                                                                                            />
                                                                                                      </div>
                                                                                                </Col>
                                                                                                <Col xxl="4" lg="6">
                                                                                                      <div className="search-box d-inline-block">
                                                                                                            <div className="position-relative">
                                                                                                                  <SearchBar
                                                                                                                        {...toolkitProps.searchProps}
                                                                                                                  />
                                                                                                                  <i className="bx bx-search-alt search-icon" />
                                                                                                            </div>
                                                                                                      </div>
                                                                                                      <p>{filterStartDate && filterEndDate ? <span className="fw-bold"><span className="normal">Fees : </span></span> : ''}<span>&nbsp;&nbsp;</span> 
                                                                                                      {/* <span className="fw-bold">{TotalPNL.totalfee != undefined ? TotalPNL.totalfee.toFixed(2) + exchangeType === 1 ? "USD" : "USDT" : 'NA'}  </span>  */}
                                                                                                      <span className="fw-bold">
                                                                                                      {TotalPNL.totalfee !== undefined
                                                                                                      ? `${TotalPNL.totalfee.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                                      : "NA"}
                                                                                                      </span>

                                                                                                      </p>

                                                                                                      <p>{filterStartDate && filterEndDate ? <span className="fw-bold"><span className="normal">Showing data from</span> {formatUTCDate(filterStartDate)} to {formatUTCDate(filterEndDate)}</span> : ''}<span>&nbsp;&nbsp;</span>
                                                                                                      {/* {TotalPNL.totalpnl > 0 ? <span className="text-success mb-0">{TotalPNL.totalpnl ? TotalPNL.totalpnl.toFixed(2) + exchangeType === 1 ? "USD" : "USDT" : 'NA'}  </span> : <span className="text-danger  mb-0">  {TotalPNL.totalpnl ? TotalPNL.totalpnl.toFixed(2) + exchangeType === 1 ? "USD" : "USDT" : 'NA'}  </span>} */}
                                                                                                      <span
                                                                                                      className={TotalPNL.totalpnl > 0 ? "text-success mb-0" : "text-danger mb-0"}
                                                                                                      >
                                                                                                      {TotalPNL.totalpnl !== undefined
                                                                                                      ? `${TotalPNL.totalpnl.toFixed(2)} ${exchangeType === 1 ? "USD" : "USDT"}`
                                                                                                      : "NA"}
                                                                                                      </span>
                                                                                                      </p>
                                                                                                </Col>
                                                                                                
                                                                                                {/* </div> */}
                                                                                          </Row>
                                                                                    </CardBody>
                                                                                    <div className="table-responsive">
                                                                                          <BootstrapTable
                                                                                                {...toolkitProps.baseProps}
                                                                                                {...paginationTableProps}
                                                                                                responsive
                                                                                                //defaultSorted={defaultSorted}
                                                                                                bordered={true}
                                                                                                classes={
                                                                                                      "table align-middle table-nowrap table-check"
                                                                                                }
                                                                                                headerWrapperClasses={"table-light"}
                                                                                                noDataIndication={emptyDataMessage}
                                                                                                keyField='order_id'
                                                                                          />
                                                                                    </div>
                                                                                    <div className="pagination pagination-rounded justify-content-end mb-2">
                                                                                          <PaginationListStandalone
                                                                                                {...paginationProps}
                                                                                          />
                                                                                    </div>
                                                                              </React.Fragment>
                                                                        )}
                                                                  </ToolkitProvider>
                                                            )}
                                                      </PaginationProvider>





                                                </CardBody>

                                          </Card>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
            </React.Fragment>
      );
};

export default ListOptions;

