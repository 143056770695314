import React from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";
import { Link } from "react-router-dom";

const IndianUserWallet = ({ currentUser, userBalance }) => {
  return (
    <Col xl="4" className="box_2">
      <Card>
        <CardBody style={{ padding: "0" }}>
          <h4 className="card-title text-capitalize">
            Wallet Details Of{" "}
            {currentUser &&
            currentUser.profile &&
            currentUser.profile.first_name
              ? currentUser.profile.first_name
              : "User"}
          </h4>
          {currentUser.is_deltakey_updated ? (
            <Row>
              <Col lg="12">
                <div className="border p-3 rounded mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-warning text-white font-size-18">
                        <i className="mdi mdi-bitcoin" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">BTC</h5>
                  </div>

                  <Row>
                    <div className="col-lg-6">
                      <div className="mt-3">
                        <p>Wallet</p>
                        <h4
                          className={
                            userBalance && userBalance.btc.balance > 0
                              ? "text-warning"
                              : "text-block"
                          }
                        >
                          {userBalance && userBalance.btc.balance
                            ? parseFloat(userBalance.btc.balance).toFixed(6)
                            : 0}
                        </h4>
                      </div>
                    </div>

                    <div className="col-lg-6 align-self-end">
                      <div className="float-end mt-3">
                        <p>Available</p>
                        <h4
                          className={
                            userBalance && userBalance.btc.available_balance > 0
                              ? "text-warning"
                              : "text-block"
                          }
                        >
                          {userBalance && userBalance.btc.available_balance
                            ? parseFloat(
                                userBalance.btc.available_balance
                              ).toFixed(6)
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col lg="12">
                <div className="border p-3 rounded mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-success text-white font-size-18">
                        <i className="mdi mdi-alpha-t-circle" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">USD/INR</h5>
                  </div>

                  <Row>
                    <div className="col-lg-6">
                      <div className="mt-3">
                        <p>Wallet</p>
                        <h4
                          className={
                            userBalance &&
                            userBalance.usd &&
                            userBalance.usd.balance > 0
                              ? "text-success"
                              : "text-block"
                          }
                        >
                          {userBalance &&
                          userBalance.usd &&
                          userBalance.usd.balance
                            ? parseFloat(userBalance.usd.balance).toFixed(2)
                            : 0}
                          /
                          {userBalance &&
                          userBalance.usd &&
                          userBalance.usd.balance_inr
                            ? parseFloat(userBalance.usd.balance_inr).toFixed(2)
                            : 0}
                        </h4>
                      </div>
                    </div>

                    <div className="col-lg-6 align-self-end">
                      <div className="float-end mt-3">
                        <p>Available</p>
                        <h4
                          className={
                            userBalance &&
                            userBalance.usd &&
                            userBalance.usd.available_balance > 0
                              ? "text-success"
                              : "text-block"
                          }
                        >
                          {userBalance &&
                          userBalance.usd &&
                          userBalance.usd.available_balance
                            ? parseFloat(
                                userBalance.usd.available_balance
                              ).toFixed(2)
                            : 0}
                          /
                          {userBalance &&
                          userBalance.usd &&
                          userBalance.usd.available_balance_inr
                            ? parseFloat(
                                userBalance.usd.available_balance_inr
                              ).toFixed(2)
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
              <Col lg="12">
                <div className="border p-3 rounded mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title rounded-circle bg-primary text-white font-size-18">
                        <i className="mdi mdi-ethereum" />
                      </span>
                    </div>
                    <h5 className="font-size-14 mb-0">ETH</h5>
                  </div>

                  <Row>
                    <div className="col-lg-6">
                      <div className="mt-3">
                        <p>Wallet</p>
                        <h4
                          className={
                            userBalance && userBalance.eth.balance > 0
                              ? "text-primary"
                              : "text-block"
                          }
                        >
                          {userBalance && userBalance.eth.balance
                            ? parseFloat(userBalance.eth.balance).toFixed(6)
                            : 0}
                        </h4>
                      </div>
                    </div>

                    <div className="col-lg-6 align-self-end">
                      <div className="float-end mt-3">
                        <p>Available</p>
                        <h4
                          className={
                            userBalance && userBalance.eth.available_balance > 0
                              ? "text-primary"
                              : "text-block"
                          }
                        >
                          {userBalance && userBalance.eth.available_balance
                            ? parseFloat(
                                userBalance.eth.available_balance
                              ).toFixed(6)
                            : 0}
                        </h4>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Alert color="success" style={{ marginTop: "75px" }}>
                  Please update your{" "}
                  <Link to="/settings" className="alert-link">
                    API credentials
                  </Link>{" "}
                  and get your wallet details.
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default IndianUserWallet;
